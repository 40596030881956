@import '../../../../styles/basics';

.bb-current-news-banner {
  position: relative;

  &:global(.bb) {
    @include bb-standalone-spacing-new();
  }

  &:global(.behaviour-settings__top-spacing--no_spacing) {
    margin-top: 0;
  }

  &:global(.behaviour-settings__bottom-spacing--no_spacing) {
    margin-bottom: 0;
  }

  &--default {
    .bb-current-news-banner__link {
      background: $black;
    }
  }

  &--emergency {
    .bb-current-news-banner__link {
      background: $turquoise-1000;
    }
  }

  &__link {
    display: block;
    padding: 16px 0;
    line-height: $line-height-large;
    // Needs for keyboard navigation effect.
    position: relative;
    z-index: 1;

    &, &:hover {
      color: $white;
      text-decoration: none;
    }
  }

  &__content {
    position: relative;
    padding: 0 32px 0 48px;
    display: flex;
    align-items: center;

    svg {
      display: block;

      path {
        fill: currentColor;
      }
    }
  }

  &__icon {
    position: absolute;
    left: 0;
    width: 24px;
    height: 17px;
  }

  &__prefix {
    font-weight: $font-weight-bold;
  }

  &__arrow {
    position: absolute;
    right: 8px;
    width: 8px;
    height: 24px;

    svg {
      transition: transform .25s ease;
    }
  }

  &__link:hover,
  &__link:focus {
    .bb-current-news-banner__arrow svg {
      transform: translateX(4px);
    }
  }

  // The same container rule as for BBHeroWithDonationWidget.
  // In this particular case using "down" breakpoints allows
  // to avoid double overriding for container on 992+
  @include media-breakpoint-down(lg) {
    :global(.container) {
      max-width: none;
      padding-left: 36px;
      padding-right: 36px;
    }
  }

  @include media-breakpoint-down(md) {
    :global(.container) {
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  @include media-breakpoint-down(sm) {
    :global(.container) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

